import React, { useState } from "react";
import Airtable from "airtable";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";

export default function GoogleOneTapLogin() {
  const Navigate = useNavigate();
  const [isPrompt, setIsPrompt] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [phone, setPhone] = useState("");
  const [valid, setValid] = useState(false);
  const [success, setSuccess] = useState(false);

  const base = new Airtable({
    apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
  }).base(process.env.REACT_APP_AIRTABLE_BASE_API_KEY);

  const handleResponse = (response) => {
    const token = response.credential;
    const decodedToken = jwtDecode(token);
    const { email, name, picture } = decodedToken;
    Navigate("/thank-you");
    base("UserData").create(
      {
        userName: name,
        phone: phone,
        profile_picture: picture,
        Email: email,
      },
      function (err, record) {
        if (err) {
          console.error(err);
          return;
        }
      }
    );
    setSuccess(true);
  };

  const handleClick = async () => {
    try {
      await window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_CLIENT_ID,
        callback: handleResponse,
      });
      await window.google.accounts.id.prompt((notification) => {
        if (notification.isNotDisplayed()) {
          alert("Try to clear the cookies or try again later!");
        }
        if (
          notification.isSkippedMoment() ||
          notification.isDismissedMoment()
        ) {
          if (notification.getDismissedReason() != "credential_returned") {
            base("UserData").create(
              {
                phone: phone,
              },
              function (err, record) {
                if (err) {
                  console.error(err);
                  return;
                }
              }
            );
            Navigate("/thank-you");
          }
        }
      });
    } catch (error) {
      console.log("from error");
      base("UserData").create(
        {
          phone: phone,
        },
        function (err, record) {
          if (err) {
            console.error(err);
            return;
          }
        }
      );
      console.log(error);
      Navigate("/error");
    }
  };

  const handleChange = (e) => {
    setPhone(e.target.value);
    setValid(
      /^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/.test(
        e.target.value
      )
    );
  };

  return (
    <div className="form-wrap">
      <div className="wrap">
        <h3>Discover what Salevant.ai can do for you</h3>
        <p
          className="sub-head"
          style={{ display: isComplete ? "none" : "block" }}
        >
          Fill out the form below to get your free trial
        </p>
        <form
          id="msform"
          action="#"
          style={{ display: isComplete ? "none" : "block" }}
        >
          <fieldset style={{ display: isPrompt ? "none" : "block" }}>
            <input
              type="text"
              name="phone"
              placeholder="Enter Phone Number"
              minLength={10}
              required
              onChange={handleChange}
            />
            <input
              type="button"
              onClick={handleClick}
              className="next action-button"
              disabled={!valid}
              value="Start Free Trial"
            />
          </fieldset>
        </form>
      </div>
    </div>
  );
}
