import React from "react";
import "./Error.css";
import { useNavigate } from "react-router-dom/dist";

export default function Error_page() {
  const Navigate = useNavigate();
  const handleClick = () => {
    Navigate("/");
  };
  return (
    // <div>
    //   <p>Requested url is not available</p>
    //   <button className="error-btn" onClick={handleClick}>
    //     Go to homepage
    //   </button>
    // </div>

    <div className="section">
      <h1 className="error">404</h1>
      <div className="page">
        Ooops!!! The page you are looking for is not found
      </div>
      <a className="back-home" href="/">
        Back to home
      </a>
    </div>
  );
}
