import ReactGA from "react-ga";

ReactGA.initialize("AW-11131338622");

export const pageview = (pathname) => {
  ReactGA.pageview(pathname);
};

export const trackEvent = (category, action, label) => {
  console.log("conversion");
  ReactGA.event({
    category,
    action,
    label,
  });
};

export const trackConversion = () => {
  console.log("tracked");
  ReactGA.event({
    category: "Conversion",
    action: "Thank You Page View",
    nonInteraction: true,
  });
};
