import React from "react";
import GoogleOneTapLogin from "./GoogleOneTapLogin";

export default function Hero() {
  return (
    <div className="Hero">
      <div className="dialog-off-canvas-main-canvas">
        <div id="block-headerpaintservice"></div>
        <div id="block-nerolac-page-title"></div>
        <div id="block-nerolac-content">
          <article>
            <span
              property="schema:name"
              content="Painting Services"
              className="hidden"
            ></span>

            <div className="layout layout--onecol">
              <div className="layout__region layout__region--content">
                <div>
                  <div>
                    <div className="spotlight-painting-service">
                      <div className="image-wrap">
                        <picture>
                          <source
                            media="(max-width: 799px)"
                            srcSet={require("../assets/img/phone_landing.webp")}
                          />
                          <source
                            media="(min-width: 800px)"
                            srcSet={require("../assets/img/hero_image_desktop.webp")}
                          />
                          <img
                            // src="./images/PUT_A_FACE.png"
                            src={require("../assets/img/hero_image_desktop.webp")}
                            className="img-fluid"
                            alt=""
                          />
                        </picture>
                      </div>

                      <GoogleOneTapLogin />
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <section className="why-us-boxes-wrap mt-5">
                      <div className="container">
                        <h3 className="what">
                          What can Salevant.ai do for your business?
                        </h3>
                        <div
                          id="col-container"
                          className="row row-cols-lg-3 row-cols-sm-1 g-lg-5 g-3"
                        >
                          <div className="col">
                            <div className="wrap">
                              <div className="icon">
                                <img
                                  src={require("../assets/img/buyer_connect.webp")}
                                  // src="https://cdn-icons-png.flaticon.com/512/3188/3188314.png"
                                  alt=""
                                />
                              </div>
                              <div className="title">Improve Buyer connect</div>
                              <p>
                                We help you understand your customer persona to
                                establish a stronger connection with them.
                              </p>
                            </div>
                          </div>
                          <div className="col">
                            <div className="wrap">
                              <div className="icon">
                                <img
                                  src={require("../assets/img/boost_sales.webp")}
                                  // src="https://cdn-icons-png.flaticon.com/512/5329/5329510.png"
                                  alt=""
                                />
                              </div>
                              <div className="title">Boost sales</div>
                              <p>
                                Our AI algorithms match buyers with the right
                                sales rep, resulting in a 10% increase in
                                conversion rates and sales growth.
                              </p>
                            </div>
                          </div>

                          <div className="col">
                            <div className="wrap">
                              <div className="icon">
                                <img
                                  src={require("../assets/img/sale_pitch.webp")}
                                  // src="https://cdn-icons-png.flaticon.com/512/1533/1533223.png"
                                  alt=""
                                />
                              </div>
                              <div className="title">
                                Personalise sales pitch
                              </div>
                              <p>
                                {" "}
                                Offer personalized features and packages for
                                each buyer to increase engagement rates and
                                buyer response by 100%
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="section-header text-center">
                        <h3 className="what">Why choose us?</h3>
                        <p>
                          Salevant.ai is the world's first AI powered sales
                          intelligence engine! <br /> <br />
                          We don't just boost your sales - we transform your
                          business. <br />
                          With us, you'll gain a competitive edge, ultimately
                          leading to a boost in conversion rates and sales by up
                          to 10%!
                        </p>
                      </div>

                      <div className="container">
                        <h3 className="how">How do we work?</h3>
                        <div
                          id="col-container"
                          className="row row-cols-lg-3 row-cols-sm-1 g-lg-5 g-3"
                        >
                          <div className="col">
                            <div className="wrap" id="how_card">
                              <div className="icon">
                                <img
                                  src={require("../assets/img/unmasking.webp")}
                                  // src="https://cdn-icons-png.flaticon.com/512/34/34852.png"
                                  alt=""
                                />
                              </div>
                              <div className="title">
                                Unmasking User Personas
                              </div>
                              <p>
                                With user profiling, we utilize segmentation to
                                help you identify and understand your users'
                                profiles, allowing you to put a face to just an
                                email address or phone number.
                              </p>
                            </div>
                          </div>
                          <div className="col">
                            <div className="wrap " id="how_card">
                              <div className="icon">
                                <img
                                  src={require("../assets/img/intelligent_insight.webp")}
                                  // src="https://cdn-icons-png.flaticon.com/512/4149/4149701.png"
                                  alt=""
                                />
                              </div>
                              <div className="title">
                                {" "}
                                Intelligent Insights Unlocking
                              </div>
                              <p>
                                Our platform uses advanced AI algorithms to
                                analyze customer data, providing you with deep
                                insights into their buying behavior.
                              </p>
                            </div>
                          </div>

                          <div className="col">
                            <div className="wrap" id="how_card">
                              <div className="icon">
                                <img
                                  src={require("../assets/img/behaviour_based.webp")}
                                  // src="https://cdn-icons-png.flaticon.com/512/2721/2721463.png"
                                  alt=""
                                />
                              </div>
                              <div className="title">
                                Behavior-Based Sales Optimizing
                              </div>
                              <p>
                                {" "}
                                Our personalized recommendations are based on
                                the buyer's behavior, enabling you to optimize
                                your sales approach and increase conversions.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                {/* <div>
                  <div>
                    <section>
                      <div className="container">
                        <div className="section-header text-center">
                          <div className="tags-wrap">
                            <ul>
                              <li>That favourite corner</li>
                            </ul>
                          </div>
                          <h3>Latest Happenings in the Paint World</h3>
                          <p>
                            Get some inspiration from these trending articles
                          </p>
                        </div>
                        <div className="views-element-container row">
                          <div
                            className="four-grid-carousel row-cols-lg-5 arrow-on-top clearfix"
                            data-flickity='{ "wrapAround": true, "contain": true, "lazyLoad": true, "pageDots": true, "prevNextButtons": true, "imagesLoaded": true, "cellAlign": "left", "lazyLoad": 4, "groupCells": true }'
                          >
                            <div className="slides views-row">
                              <div className="wrap">
                                <div className="image">
                                  <img
                                    src={require("./assets/img/Top10WallPaintColourIdeasForStudyRoom.jpg.webp")}
                                    className="img-fluid lazyload"
                                    alt="Top 10 Wall Paint Colour Ideas For Study Room"
                                    title="Top 10 Wall Paint Colour Ideas For Study Room"
                                    loading="lazy"
                                    width="442px"
                                    height="590px"
                                  />
                                </div>
                                <div className="content">
                                  <h4 className="title">
                                    Top 10 Wall Paint Colour Ideas For Study
                                    Room
                                  </h4>
                                  <a href="#" className="stretched-link">
                                    Read Blog{" "}
                                    <i className="fa-solid fa-chevron-right"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="slides views-row">
                              <div className="wrap">
                                <div className="image">
                                  <img
                                    src={require("./assets/img/TransformYourHomeWithTheseTrendingAccentWallIdeas.jpg.webp?itok=2Kuk59z5")}
                                    className="img-fluid lazyload"
                                    alt="Transform Your Home With These Trending Accent Wall Ideas"
                                    title="Transform Your Home With These Trending Accent Wall Ideas"
                                    loading="lazy"
                                    width="442px"
                                    height="590px"
                                  />
                                </div>
                                <div className="content">
                                  <h4 className="title">
                                    Transform Your Home With These Trending
                                    Accent Wall Ideas
                                  </h4>
                                  <a href="#" className="stretched-link">
                                    Read Blog{" "}
                                    <i className="fa-solid fa-chevron-right"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="slides views-row">
                              <div className="wrap">
                                <div className="image">
                                  <img
                                    src={require("./assets/img/Top10AccentWallColourCombinationsToMatchAnyStyle.jpg.webp?itok=zTMPjbCR")}
                                    className="img-fluid lazyload"
                                    alt="Top 10 Accent Wall Colour Combinations To Match Any Style"
                                    title="Top 10 Accent Wall Colour Combinations To Match Any Style"
                                    loading="lazy"
                                    width="442px"
                                    height="590px"
                                  />
                                </div>
                                <div className="content">
                                  <h4 className="title">
                                    Top 10 Accent Wall Colour Combinations To
                                    Match Any Style
                                  </h4>
                                  <a href="#" className="stretched-link">
                                    Read Blog{" "}
                                    <i className="fa-solid fa-chevron-right"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="slides views-row">
                              <div className="wrap">
                                <div className="image">
                                  <img
                                    src={require("./assets/img/GuideToChoosingThePerfectAccentWallColours.jpg.webp?itok=IiOHYy1V")}
                                    className="img-fluid lazyload"
                                    alt="Guide To Choosing The Perfect Accent Wall Colours"
                                    title="Guide To Choosing The Perfect Accent Wall Colours"
                                    loading="lazy"
                                    width="442px"
                                    height="590px"
                                  />
                                </div>
                                <div className="content">
                                  <h4 className="title">
                                    Guide To Choosing The Perfect Accent Wall
                                    Colours
                                  </h4>
                                  <a href="#" className="stretched-link">
                                    Read Blog{" "}
                                    <i className="fa-solid fa-chevron-right"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> 
                    </section>
                  </div>
                </div>*/}
              </div>
            </div>
          </article>
        </div>

        <div id="block-footer">
          <div>
            <footer className="main-footer">
              <div className="container">
                <div className="row flex-row-reverse">
                  <div
                    id="footer-address"
                    className="col-lg-3 mt-5 mt-lg-0 text-center text-sm-start"
                  >
                    <div className="logo">
                      <a href="#">
                        <img
                          src={require("../assets/img/landing_logo.webp")}
                          className="img-fluid"
                          loading="lazy"
                          alt=""
                          width="600px"
                          height="59px"
                        />
                      </a>
                    </div>
                    <div className="social-links-wrap">
                      <div className="label">Follow us on:</div>
                      <ul>
                        <li>
                          <a
                            href="https://www.facebook.com/yogleads"
                            target="_blank"
                            rel="noopener nofollow noreferrer"
                          >
                            <img
                              src={require("../assets/img/facebook.png")}
                              alt=""
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://twitter.com/YogLeads"
                            target="_blank"
                            rel="noopener nofollow noreferrer"
                          >
                            <img
                              src={require("../assets/img/twitter.png")}
                              alt=""
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/company/yogleads-technology"
                            target="_blank"
                            rel="noopener nofollow noreferrer"
                          >
                            <img
                              src={require("../assets/img/linkedin.png")}
                              alt=""
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.reddit.com/r/AIforSales/"
                            target="_blank"
                            rel="noopener nofollow noreferrer"
                          >
                            <img
                              src={require("../assets/img/reddit.png")}
                              alt=""
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <address>
                      <span className="contact-at">Contact us at:</span>
                      <br />
                      Veriproc Solutions Pvt. Ltd.
                      <br />
                      7th Floor, AltF, ETT Trade Tower - 2, <br />
                      Area: Sector - 132, <br />
                      City: Noida,UP <br />
                      Pin: 201301
                      <br />
                      Customer Care No <br />
                      India: +91-88829-08807 <br />
                      US: +1-832-275-7430
                      <br />
                    </address>
                  </div>
                </div>
              </div>
              <div className="bottom">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      {/* <div className="bottom-links">
                        <a href="#">Home</a>
                        <a href="#privacy-policy.html">Privacy Policy</a>
                        <a href="#terms-and-conditions.html">
                          Terms &amp; Conditions
                        </a>
                        <a href="#quality-policy.html">Quality Policy</a>
                        <a href="#sitemap.html">Site Map</a>
                      </div> */}
                    </div>
                    <div className="col-lg-6 text-lg-end text-center mt-3 mt-lg-0">
                      © 2023 Salevant.ai Limited All Rights Reserved. A
                      Subsidiary of Yogleads, India
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="visv-0">{isPrompt && <Form />}</div> */}
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
}
