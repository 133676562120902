import "./assets/css/bootstrap.css";
import "./assets/css/main.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import "./App.css";
import Hero from "./components/Hero";
import Thank_you from "./components/Thank_you";
import Error_page from "./components/Error_page";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Hero />} />
          <Route exact path="/thank-you" element={<Thank_you />} />
          <Route path="*" element={<Error_page />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
