import React, { useEffect } from "react";
import { trackEvent, trackConversion } from "../analytics";
import "./Thank-you.css";

export default function Thank_you() {
  useEffect(() => {
    trackEvent("Conversion", "Submit", "Lead Form", {
      send_to: "AW-11131338622/5F67CKS44pYYEP7-6rsp",
    });
    trackConversion();
    const Timer = setTimeout(() => {
      window.location.replace("https://www.yogleads.com");
    }, 5000);

    return () => clearTimeout(Timer);
  }, []);

  return (
    <div className="thank_you_container">
      <div className="image-wrap">
        <picture>
          <source
            media="(max-width: 799px)"
            srcSet={require("../assets/img/Thank_you_phone.webp")}
          />
          <source
            media="(min-width: 800px)"
            srcSet={require("../assets/img/Thank_you_desktop.webp")}
          />
          <img
            src={require("../assets/img/Thank_you_desktop.webp")}
            className="img-fluid"
            alt=""
          />
        </picture>
      </div>
    </div>
  );
}
